import axios from 'axios';
import { BASE_URL } from '../common/constant';
const httpService = (
  serviceUrl,
  type = 'get',
  data,
  service = 'dsp-api',
  multipart = false,
  additionalHeaders = {},
  stream = false,
  additionalConfig = {},
) => {

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    ...additionalConfig,
  };
  if (additionalHeaders) {
    config.headers = { ...config.headers, ...additionalHeaders };
  }
  if (multipart) config.headers['Content-Type'] = 'multipart/form-data';
  if (stream) config['responseType'] = 'blob';
  // let { accessToken } = getSessionData();
  // if (accessToken) {
  //   config.headers.Authorization = `Bearer ${accessToken}`;
  // }

  /*
  if (agencyId) {
    config.headers['X-AgencyId'] = agencyId;
  } else {
    config.headers['X-AgencyId'] = 'master';
  }
  */

  // config.cancelToken = source.token;

  let url = `${BASE_URL}/${service}/${serviceUrl}`;
  switch (type) {
    case 'get': {
      const promise = axios.get(url, config);
      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };
      return promise;
    }
    case 'post':
    case 'put':
    case 'patch':
      return axios[type](url, data, config);
    case 'delete':
      if (data) config.data = data;
      return axios.delete(url, config);
    default: {
      break;
    }
  }
  return null;
};

export default httpService;
