import React from 'react';
import Select from 'react-select';
import { InputLabel } from '@material-ui/core';
import './common.css';

const NewSelect = (props) => {
  return (
    <div className={`position-relative ${props?.className}`}>
      {props.placeholder && (
        <InputLabel className="mb-0 flotting-label">
          {props?.placeholder || `Select`}
          {props.required && (
            <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
              {'\u2009*'}
            </span>
          )}
        </InputLabel>
      )}
      <Select
        {...props}
        className="new-select"
        styles={{
          control: (provided) => ({
            ...provided,
            height: '50px', // Adjust the height as needed
            marginTop: '-13px',
            marginBottom: '15px',
          }),
        }}
        placeholder="" // Remove placeholder
      />
    </div>
  );
};

export default NewSelect;
