import React, { useState, useEffect } from 'react';
import './Leadform.css';
import {Grid } from '@material-ui/core';
import LeadFormPage from '../lead-form-page/LeadFormPage';

const LeadForm = () => {
  const [isMediumOrLarger, setIsMediumOrLarger] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMediumOrLarger(window.innerWidth >= 475);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const backgroundColor = isMediumOrLarger ? '#FFD580' : 'white';

  return (
    <>
      <div className="landing-page align-items-center p-4" style={{ backgroundColor ,minHeight: '100vh'}}>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            // xs={12}
            // md={8}
            // lg={6}
            xs={4}
            
            className="d-flex flex-column justify-content-end align-items-center temp"
          >
            <div>
              <LeadFormPage />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LeadForm;
