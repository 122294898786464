import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';  // Replace with the path to your main component
import './index.css';
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/* <Router> */}
        <App />
      {/* </Router> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
