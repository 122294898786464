// PageNotFound.js
import React, { useEffect } from 'react';
import './page-not-found.css';

const PageNotFound = () => {
  useEffect(() => {
    const colors = ['#152a68', 'royalblue', 'seagreen', 'tomato'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    document.querySelector('.flex-container').style.backgroundColor = colors[randomIndex];
  }, []); 

  return (
    <div className="flex-container">
      <div className="text-center">
        <h1>
          <span className="fade-in" id="digit1">4</span>
          <span className="fade-in" id="digit2">0</span>
          <span className="fade-in" id="digit3">4</span>
        </h1>
        <h3 className="fadeIn">PAGE NOT FOUND</h3>
        {/* <button type="button" name="button">Return To Home</button> */}
      </div>
    </div>
  );
};

export default PageNotFound;
