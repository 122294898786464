export const CONTENT_URL = process.env.REACT_APP_CONTENT_URL //ENV === ENV_OPTIONS.QA ? "http://qa-content.adzylytics.com" : "http://content.adzylytics.com";
export const BASE_URL = process.env.REACT_APP_BASE_URL//ENV === ENV_OPTIONS.QA ? "http://qa-api.adzylytics.com" :  "http://api.adzylytics.com"
export const EVENT_URL = process.env.REACT_APP_EVENT_URL;

export const STALE_TIME = {
  NONE: 0,
  FIVE_MIN: 300 * 1000,
  HALF_HOUR: 1800 * 1000,
  ONE_HOUR: 3600 * 1000,
  TWO_HOUR: 7200 * 1000,
};

export const CACHE_TIME = {
  ZERO: 0,
  FIVE_MIN: 300 * 1000,
  HALF_HOUR: 1800 * 1000,
  ONE_HOUR: 3600 * 1000,
  TWO_HOUR: 7200 * 1000,
};
