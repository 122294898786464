import httpService from "./httpService";

const Services = {
  saveFormData: (payload) =>
    httpService(`leadFormData`, "post", payload, "dsp-api/public"),
  getFormFields: (campaignId, lineItemId) =>
    httpService(
      `leadForm/campaign/${campaignId}/lineItem/${lineItemId}`,
      "get",
      null,
      "dsp-api/public"
    ),
};

export default Services;
