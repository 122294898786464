import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Button } from "@material-ui/core";
import NewTextField from "../../common/NewTextField";
import NewSelect from "../../common/NewSelect";
import Services from "../../service_utils/services";
import { useSnackbar } from "notistack";
import { CONTENT_URL, EVENT_URL } from "../../common/constant";
// import Navbar from "./Navbar";
import { useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query"; // or from the appropriate library
import checkIcon from "../../assets/icons/check_icon.png";
import { useNavigate } from "react-router-dom";

const LeadFormPage = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const pathSegments = location.pathname.split("/");
  const campaignId = pathSegments[2]; // index 4 corresponds to "campaignId"
  const lineItemId = pathSegments[4]; // index 6 corresponds to "lineItemId"
  const leadFormId = pathSegments[6];
  const [formData, setFormData] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [landingFormFieldsData, setLandingFormFieldsData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const navigate = useNavigate();
  const [googleAnalyticsScriptLoaded, setGoogleAnalyticsScriptLoaded] =
    useState(false);
  const flag = process.env.REACT_APP_FLAG;
  const searchParams = new URLSearchParams(location.search);
  const rUrl = searchParams.get("r");
  // const adzClkId = searchParams.get("adzClkId");


  const decodeBase64Url = (encodedUrl) => {
    try {
      const base64String = encodedUrl.replace(/-/g, "+").replace(/_/g, "/");
      const binaryString = window.atob(base64String);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(byteArray);
    } catch (error) {
      console.error("Error decoding Base64 URL:", error);
      return null;
    }
  };
  // Decode the Base64 encoded URL
  const decodedRUrl = rUrl ? decodeBase64Url(rUrl) : null;

  useEffect(() => {
    const fetchFormFields = async () => {
      try {
        const response = await Services.getFormFields(campaignId, lineItemId);
        setLandingFormFieldsData(response.data?.leadFormDTO);
        setFetchData(true);
      } catch (error) {
        navigate("/404");
        console.error("Error fetching form fields:", error);
      }
    };

    if (!landingFormFieldsData?.formFieldsList) {
      fetchFormFields();
    }
  }, [campaignId, lineItemId, landingFormFieldsData?.formFieldsList]);

  useEffect(() => {
    if (landingFormFieldsData?.formFieldsList) {
      const initialFormData = {};
      landingFormFieldsData.formFieldsList.forEach((field) => {
        initialFormData[field.label] =
          field.type === "dropdown" &&
          Array.isArray(field.values) &&
          field.values.length > 0
            ? field.values[0]
            : "";
      });
      setFormData(initialFormData);
    }
  }, [landingFormFieldsData]);

  useEffect(() => {
    if (flag === "true") {
      if (
        fetchData &&
        landingFormFieldsData?.formFieldsList &&
        !googleAnalyticsScriptLoaded
      ) {
        // Google Analytics script
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-4CZX4S8X53";
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-4CZX4S8X53");

          // Mark the script as loaded to prevent re-execution
          setGoogleAnalyticsScriptLoaded(true);
        };

        document.head.appendChild(script);
      }
    }
  }, [
    flag,
    fetchData,
    landingFormFieldsData?.formFieldsList,
    googleAnalyticsScriptLoaded,
  ]);

  const handleInputChange = (name) => (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropDownChange = (name) => (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setSelectedValue(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const { mutate: handleSubmit } = useMutation(
    async () => {
      const payload = {
        formData,
        campaignId,
        lineItemId,
        leadFormId,
      };
      const response = await Services.saveFormData(payload);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
      onSuccess: () => {
        setFormSubmitted(true);
        // enqueueSnackbar("Save Successfully.", { variant: "success" });
      },
    }
  );
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    handleSubmit(); // Call the mutation
  };
  useEffect(() => {
    if (formSubmitted && decodedRUrl) {
      // Redirect to the external URL after 5 seconds
      setTimeout(() => {
        window.location.href = decodedRUrl;
      }, 3000);
    }
  }, [formSubmitted, decodedRUrl]);
  return (
    <>
      {!formSubmitted ? (
        <div className="">
          <div
            elevation={3}
            className="p-3 d-flex align-items-center justify-content-center logoImg"
            // style={{ height: "150px", maxWidth: "300px", margin: "auto" }}
          >
            {landingFormFieldsData?.logoUrl && (
              <img
                className="rounded-sm p-2 logoImg"
                src={`${CONTENT_URL}/${landingFormFieldsData.logoUrl}`}
                alt="logo"
                // style={{ width: "300px", height: "150px", objectFit: "contain" }}
              />
            )}
          </div>

          <div className="pt-3 p-4 mt-4">
            <div className="justify-content-center d-flex mb-3">
              <Typography component="h6" style={{ overflowWrap: "break-word" }}>
                {landingFormFieldsData?.description}
              </Typography>
            </div>

            <div className="d-flex justify-content-center">
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", overflowWrap: "break-word" }}
              >
                {landingFormFieldsData?.title}
              </Typography>
            </div>
          </div>
          <div justify="center" alignItems="center">
            <Container>
              <Paper className="p-2" style={{ width: "330px" }}>
                <form onSubmit={handleFormSubmit} className="mt-2">
                  {landingFormFieldsData?.formFieldsList &&
                    landingFormFieldsData?.formFieldsList.map(
                      (field, index) => (
                        <div key={index}>
                          {field.type === "text" ||
                          field.type === "email" ||
                          field.type === "number" ? (
                            <NewTextField
                              required
                              className="mb-4"
                              type={field.type}
                              name={field.label}
                              label={field.label}
                              value={formData[field.label]}
                              onChange={handleInputChange(field.label)}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                            />
                          ) : field.type === "dropdown" ? (
                            <NewSelect
                              variant="outlined"
                              required
                              name={field.label}
                              value={formData[field.value]}
                              onChange={handleDropDownChange(field.label)}
                              options={field.options.map((value) => ({
                                label: value,
                                value: value,
                              }))}
                              placeholder={`${field.label}`}
                              className="py-1 text-start"
                            />
                          ) : null}
                        </div>
                      )
                    )}
                  <Button
                    type="submit"
                    variant="contained"
                    className="button"
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#EF6A0A",
                      color: "white",
                    }}
                  >
                    {landingFormFieldsData?.buttonName}
                  </Button>
                </form>
              </Paper>
            </Container>
          </div>
        </div>
      ) : (
        <div className="">
          <div
            elevation={3}
            className="p-3 d-flex align-items-center justify-content-center logoImg logo"
            // style={{ height: "150px", maxWidth: "300px", margin: "auto" }}
          >
            {landingFormFieldsData?.logoUrl && (
              <img
                className="rounded-sm p-2 logoImg"
                src={`${CONTENT_URL}/${landingFormFieldsData.logoUrl}`}
                alt="logo"
                // style={{ width: "300px", height: "150px", objectFit: "contain" }}
              />
            )}
          </div>
          <div className=" justify-content-center mt-3">
            {/* <img
              src={`${EVENT_URL}/events/conversion/${campaignId}?adzClkId=${adzClkId}&event=&price=&currency=`}
              style={{width: "1px", height: "1px", border: 0}}
            /> */}
            {/* <CheckCircleIcon style={{ color: "green", fontSize: 40 }} /> */}
            <div className="sm check mt-5">
              <img src={checkIcon} style={{ width: "30px" }} alt="" />
            </div>

            <div className="thankumsg mt-3">
              <Typography
                variant="h5"
                className="font-weight-bold overflow-wrap-break-word"
                style={{ fontWeight: "bold", overflowWrap: "break-word" }}
              >
                {landingFormFieldsData?.thankYouNote}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadFormPage;


