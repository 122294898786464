import "./App.css";
import { SnackbarProvider } from "notistack";

import AppRouter from "./routes";
function App() {
  return (
    <div className="App">
      <div className="main-content">
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
            fontWeight: "bold",
          }}
        >
          <AppRouter />
        </SnackbarProvider>
      </div>
    </div>
  );
}

export default App;
