// Router.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PageNotFound from './component/page-not-found/page-not-found';
import LeadForm from './component/lead-form/LeadForm';

const AppRouter = () => {
  return (
    <Router>
    <Routes>
      <Route path="/campaign/:campaignId/lineItem/:lineItemId/leadForm/:leadFormId" element={<LeadForm />} />
      <Route path="/" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound to="/404" />} />
    </Routes>
    </Router>
  
  );
};

export default AppRouter;
